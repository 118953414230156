import { render, staticRenderFns } from "./ServiceRequestList.vue?vue&type=template&id=800b2128&scoped=true&"
import script from "./ServiceRequestList.vue?vue&type=script&lang=js&"
export * from "./ServiceRequestList.vue?vue&type=script&lang=js&"
import style0 from "./ServiceRequestList.vue?vue&type=style&index=0&id=800b2128&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "800b2128",
  null
  
)

export default component.exports