<!-- eslint-disable no-const-assign -->
<!-- eslint-disable no-const-assign -->
<template>

  <div class="service__request">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Kitchen’s View
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <div style="display: flex;">
            <b-button
              variant="success"
              class="mr-50"
              style="height: 40px;"
              @click="downloadPDF"
            >
              <feather-icon
                icon="FileIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Generate PDF</span>
            </b-button>
            <div class="date__picker_block white-bg-dp">
              <b-form-group
                label=""
              >
                <b-input-group>
                  <flat-pickr
                    id="main-date"
                    v-model="parentServiceDate"
                    class="form-control"
                    placeholder="Select Date"
                    :config="flatPickrConfig"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </b-col>
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <b-tab
            :active="$route.params.type == 'my-requests'"
            lazy
            @click="changeRoute('my-requests')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Assigned To Me <span class="rounded-number">{{ myRequestsCount }}</span></span>
            </template>
            <my-service-request
              class="mt-2 pt-75"
              :parent-service-date.sync="parentServiceDate"
              :request-type-options="requestTypeOptions"
              :assignee-options="assigneeOptions"
              :venue-options="venueOptions"
              @emitCountServiceRequest="countServiceRequests"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'all-service-requests'"
            lazy
            @click="changeRoute('all-service-requests')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All Service Requests <span class="rounded-number">{{ allCount }}</span></span>
            </template>
            <all-service-request
              class="mt-2 pt-75"
              :parent-service-date.sync="parentServiceDate"
              :request-type-options="requestTypeOptions"
              :assignee-options="assigneeOptions"
              :venue-options="venueOptions"
              @emitCountServiceRequest="countServiceRequests"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BTab, BTabs, BDropdown, BDropdownItem, BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllServiceRequest from './all-service-request/AllServiceRequest.vue'
import MyServiceRequest from './my-service-request/MyServiceRequest.vue'

export default {
  components: {
    BRow,
    BButton,
    BCol,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,

    flatPickr,

    AllServiceRequest,
    MyServiceRequest,
  },
  data() {
    return {
      parentServiceDate: this.$route.query.serviceDate || '',
      allCount: 0,
      myRequestsCount: 0,
      requestTypeOptions: [],
      assigneeOptions: [],
      venueOptions: [],
      currentTabTitle: '',
      pageTabs: [
        {
          title: 'Assigned To Me',
          param: 'my-requests',
          count: 0,
        },
        {
          title: 'All Service Requests',
          param: 'all-service-requests',
          count: 0,
        },
      ],
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, disableMobile: true,
      },
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countServiceRequests()
    this.$http.get('operation/service-forms/respond-with/duty-officers-workflow', { params: { department: true } })
      .then(response => {
        const workFlowCats = response.data.workFlowCats ?? []
        this.assigneeOptions = response.data.dutyOfficers ?? []
        if (response.data.serviceDescription && response.data.serviceDescription.customFields) {
          const venueObject = response.data.serviceDescription.customFields.find(o => o.label === response.data.venueField)
          if (venueObject) {
            this.venueOptions = venueObject.extraOptions
          }
        }

        workFlowCats.forEach(element => {
          this.requestTypeOptions.push({
            category: element.name,
            name: null,

            _id: element._id,
          })
          element.workFlows.forEach(flow => {
            this.requestTypeOptions.push({
              name: flow.name,

              _id: flow._id,
            })
          })
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },
    downloadPDF() {
      let serviceDate = ''
      if (this.$route.query.serviceDate) {
        serviceDate = this.$route.query.serviceDate
      }
      if (serviceDate) {
        // let search = ''
        // if (this.$route.query.search) {
        //   search = this.$route.query.search
        // }
        // let sortBy = ''
        // if (this.$route.query.sortBy) {
        //   sortBy = this.$route.query.sortBy
        // }
        // let sortDesc = ''
        // if (this.$route.query.sortDesc) {
        //   sortDesc = this.$route.query.sortDesc
        // }
        // let venue = []
        // if (this.$route.query.venue) {
        //   venue = this.$route.query.venue
        // }
        // let offering = []
        // if (this.$route.query.offering) {
        //   offering = this.$route.query.offering
        // }
        // let dueDate = ''
        // if (this.$route.query.dueDate) {
        //   dueDate = this.$route.query.dueDate
        // }
        // let requestType = []
        // if (this.$route.query.requestType) {
        //   requestType = this.$route.query.requestType
        // }
        // let assignee = []
        // if (this.$route.query.assignee) {
        //   assignee = this.$route.query.assignee
        // }
        // let status = ''
        // if (this.$route.query.status) {
        //   status = this.$route.query.status
        // }
        // let type = ''
        // if (this.$route.query.type) {
        //   type = this.$route.query.type
        // }
        // console.log(window.location.search)
        const formData = new FormData()
        formData.append('url', `${window.location.origin}/operation/download/kitchen-view${window.location.search}`)
        formData.append('landscape', true)
        this.$http.post('operation/pdf/download', formData, {
          headers: { Accept: 'application/pdf' },
          responseType: 'arraybuffer',
        })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `Kitchen View ${this.parentServiceDate}.pdf`
            link.click()
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select a date range to generate the PDF',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    countServiceRequests() {
      this.$http.get('operation/service-requests/counts')
        .then(response => {
          this.allCount = response.data.allCount
          this.myRequestsCount = response.data.myRequestsCount

          this.pageTabs.forEach(element => {
            if (element.title === 'All Service Requests') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allCount
            } else if (element.title === 'Assigned To Me') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.myRequestsCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
