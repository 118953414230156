import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useMyServiceRequestList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refMyServiceRequestsTable = ref(null)

  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalServiceRequests = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'stringID')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const venueFilter = ref([])
  if (Array.isArray(route.query.venue)) {
    venueFilter.value = route.query.venue
  } else if (route.query.venue) {
    venueFilter.value = [route.query.venue]
  }
  const offeringFilter = ref([])
  if (Array.isArray(route.query.offering)) {
    offeringFilter.value = route.query.offering
  } else if (route.query.offering) {
    offeringFilter.value = [route.query.offering]
  }
  const toDeletedID = ref(null)
  const type = ref('my-requests')
  const serviceRequestData = ref([])
  const kitchenItemsData = ref([])
  const dueDateFilter = ref(route.query.dueDate || '')
  const serviceDateFilter = ref(route.query.serviceDate || '')
  const venueFieldName = ref('')
  const requestTypeFilter = ref([])
  if (Array.isArray(route.query.requestType)) {
    requestTypeFilter.value = route.query.requestType
  } else if (route.query.requestType) {
    requestTypeFilter.value = [route.query.requestType]
  }
  const assigneeFilter = ref([])
  if (Array.isArray(route.query.assignee)) {
    assigneeFilter.value = route.query.assignee
  } else if (route.query.assignee) {
    assigneeFilter.value = [route.query.assignee]
  }
  let initialLoad = true

  const dataMeta = computed(() => {
    let localItemsCount = refMyServiceRequestsTable.value ? refMyServiceRequestsTable.value.localItems.length : 0
    if (serviceDateFilter.value && localItemsCount) {
      localItemsCount -= 1
    }
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalServiceRequests.value,
    }
  })

  const refetchData = () => {
    refMyServiceRequestsTable.value.refresh()
  }

  watch([searchQuery, venueFilter, offeringFilter, dueDateFilter, serviceDateFilter, requestTypeFilter, assigneeFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchServiceRequests = (ctx, callback) => {
    store
      .dispatch('app-my-service-requests/fetchServiceRequests', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        venue: venueFilter.value,
        offering: offeringFilter.value,
        dueDate: dueDateFilter.value,
        serviceDate: serviceDateFilter.value,
        type: type.value,
        requestType: requestTypeFilter.value,
        assignee: assigneeFilter.value,
      },
      { root: true })
      .then(response => {
        const {
          serviceRequests, total, venueField, kitchenItems,
        } = response.data
        venueFieldName.value = venueField
        totalServiceRequests.value = total
        kitchenItemsData.value = kitchenItems
        if (serviceDateFilter.value) {
          const totals = []
          kitchenItems.forEach(kitchenItem => {
            totals.push({ key: kitchenItem.id, value: 0 })
          })
          serviceRequests.forEach(arrayItem => {
            kitchenItems.forEach(kitchenItem => {
              arrayItem.serviceDescription.forEach(description => {
                if (kitchenItem.id === description.id) {
                  let counter = 0
                  if (description.type === 'file') {
                    if (description.value) {
                      /* eslint-disable-next-line no-plusplus */
                      counter++
                    }
                  } else if (description.type === 'title-and-text') {
                    if (description.value) {
                      /* eslint-disable-next-line no-plusplus */
                      counter++
                    }
                  } else if (description.type === 'short-answer') {
                    if (description.value) {
                      /* eslint-disable-next-line no-plusplus */
                      counter++
                    }
                  } else if (description.type === 'long-answer') {
                    if (description.value) {
                      /* eslint-disable-next-line no-plusplus */
                      counter++
                    }
                  } else if (description.type === 'multi-select') {
                    if (description.value.length) {
                      /* eslint-disable-next-line no-plusplus */
                      counter += description.value.length
                    }
                  } else if (description.type === 'single-select') {
                    if (description.value) {
                      /* eslint-disable-next-line no-plusplus */
                      counter++
                    }
                  } else if (description.value) {
                    /* eslint-disable-next-line no-plusplus */
                    counter += description.value
                  }

                  if (counter) {
                    totals.forEach((item, key) => {
                      if (kitchenItem.id === item.key) {
                        let val = item.value
                        /* eslint-disable-next-line no-plusplus */
                        val += counter
                        totals[key] = { key: kitchenItem.id, value: val }
                      }
                    })
                  }
                }
              })
            })
          })

          serviceRequests.push({
            // eslint-disable-next-line object-shorthand
            _id: '', totals: totals, date: '', serviceDescription: '', customerName: '', customerContact: '', assignees: '', remarks: '',
          })
        }
        serviceRequestData.value = serviceRequests
        callback(serviceRequests)
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              venue: venueFilter.value,
              offering: offeringFilter.value,
              dueDate: dueDateFilter.value,
              serviceDate: serviceDateFilter.value,
              requestType: requestTypeFilter.value,
              assignee: assigneeFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteServiceRequest = () => {
    store
      .dispatch('app-my-service-requests/deleteServiceRequest', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveServiceRequestStatusVariant = status => {
    if (status === 'completed') return 'success'
    if (status === 'pending') return 'warning'
    return 'primary'
  }

  return {
    fetchServiceRequests,
    perPage,
    currentPage,
    totalServiceRequests,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMyServiceRequestsTable,
    venueFieldName,

    resolveServiceRequestStatusVariant,
    refetchData,

    toDeletedID,
    deleteServiceRequest,
    serviceRequestData,
    kitchenItemsData,

    // Extra Filters
    venueFilter,
    offeringFilter,
    dueDateFilter,
    serviceDateFilter,
    requestTypeFilter,
    assigneeFilter,
  }
}
